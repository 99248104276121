<template>
    <div class="declare">
        <div class="declare_step">
            <el-steps :active="active" finish-status="success" align-center>
                <el-step title="项目信息"></el-step>
                <el-step title="团队成员"></el-step>
                <el-step title="指导教师"></el-step>
            </el-steps>
        </div>
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    data () {
        return {
            active:0,
        };
    },
    methods: {
        getPath(){
            if(this.$route.name=="Projectinfor"){
                this.active = 0
            }else if(this.$route.name=="Newmembers"){
                this.active = 1
            }else if(this.$route.name=="Newteacher"){
                this.active = 2
            }
        }
    },
    watch:{
        $route(){
            this.getPath()
        }
    },
    mounted () {
        this.getPath()
    },
}
</script>
<style scoped>
@import './../../assets/css/student/Projectapplication.css';
</style>
<style>
/* .declare_form .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item{
    width: 32%;
} */
.declare_form_one .el-form-item__content{
    width: calc(100% - 120px);
}
.declare_form_two .el-form-item__label{
    width: 100%!important;
    text-align: left!important;
}
.declare_form_two .el-textarea__inner{
    resize: none;
}
.declare_tutor .el-form-item__content{
    width: calc(100% - 120px);
}
.declare_project .el-form-item__content{
    width: 100%;
}
.declare_form_two .el-form-item__content{
    width: 100%;
}
.declare_form .el-date-editor.el-input{
    width:100%;
}
.declare_tutor .el-textarea__inner{
    resize: none;
}
.declare_project .el-textarea__inner{
    resize: none;
}
.add_Popup .el-dialog__header{
    background: #409eff;
    padding: 0 15px;
    height: 48px;
    line-height: 48px;
}
.add_Popup .el-dialog__title{
    color: #fff;
}
.add_Popup .el-input__inner{
    width: 300px;
}
.add_Popup .el-dialog__footer{
    text-align: center;
}
.add_Popup .el-textarea__inner{
    resize: none;
}
</style>